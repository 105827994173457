// src/data/questions.js

const questions = [
  {
    id: "q1",
    text: "A program is required for a UK weather service to convert temperatures entered by the user from Celsius to Fahrenheit. Only temperatures from -10°C to 35°C are valid, covering the typical range of weather temperatures in the UK.\n\nMultiplying the Celsius temperature by 2 and then adding 30 generates an approximate Fahrenheit temperature suitable for weather reports.\n\nThe table shows accurate test data for a functional program:\n[Display image: \\src\\assets\\temperature_table.png]\n\nTask\nAmend the code to:\n- Create an integer variable named celsius and set it to 0\n- Take the input from the user and convert it to an integer\n- Check that the inputted temperature is between -10 and 35\n- Multiply celsius by 2, add 30, and assign the result to the variable fahrenheit\n- Join strings together with concatenation\n- Display an error message for invalid inputs\n\nNote: Do not add any additional functionality.",
    marks: 10,
    summary: "Convert Celsius to Fahrenheit with input validation.",
    initialCode: `# Global variables
fahrenheit = 0
# =====> Add a line to create an integer variable named 'celsius' and set it to 0

# Main program
# =====> Complete the line to take the input from the user and convert it to an integer
celsius =

# =====> Complete the if statement to check that the inputted temperature is between -10 and 35
if ((          )       (          )):
    # =====> Complete the line to multiply celsius by 2, add 30, and assign the result to the variable fahrenheit
    fahrenheit
    # =====> Complete the line to join strings together with concatenation
    print(str(celsius)  "°C is approximately "  str(fahrenheit)  "°F")
else:
    # =====> Add a line to display an error message`,
    prompt: `Task Overview:

Evaluate the student's submitted code against the 10 provided criteria. The student was asked to amend the original code as per specific instructions. Each modification is worth 1 mark, leading to a total of 10 marks.

The student's response will be embedded between the <student_response> and </student_response> tags at the end of this prompt.


Original Question for the Student:

A program is required for a UK weather service to convert temperatures entered by the user from Celsius to Fahrenheit. Only temperatures from -10°C to 35°C are valid, covering the typical range of weather temperatures in the UK. Multiplying the Celsius temperature by 2 and then adding 30 generates an approximate Fahrenheit temperature suitable for weather reports.

The table shows accurate test data for a functional program:

Input	Output
-11	Invalid input
-10	-10°C is approximately 10°F
15	15°C is approximately 60°F
35	35°C is approximately 100°F
36	Invalid input
Amend the code shown below to:

Create an integer variable named celsius and set it to 0
Take the input from the user and convert it to an integer
Check that the inputted temperature is between -10 and 35
Multiply celsius by 2, add 30, and assign the result to the variable fahrenheit
Join strings together with concatenation
Display an error message
Do not add any additional functionality.

Unedited Original Code:

# Global variables
fahrenheit = 0
# =====> Add a line to create an integer variable named 'celsius' and set it to 0

# Main program
# =====> Complete the line to take the input from the user and convert it to an integer
celsius =

# =====> Complete the if statement to check that the inputted temperature is between -10 and 35
if ((          )       (          )): 
    # =====> Complete the line to multiply celsius by 2, add 30, and assign the result to the variable fahrenheit
    fahrenheit 
    # =====> Complete the line to join strings together with concatenation
    print(str(celsius)  "°C is approximately "  str(fahrenheit) + "°F")
else:
    # =====> Add a line to display an error message

Evaluation Instructions:

Evaluate only what is between <student_response> and </student_response> tags as the student's response.
Carefully compare the student's code with the original code to identify any modifications or completions.
For each criterion, provide an explanation that specifically addresses that criterion's requirements.
Ensure that the expected code and student's code snippets directly relate to the criterion being evaluated.
For the String Concatenation in Print Statement criterion, ignore minor whitespace differences. Focus on the correct use of + operators for concatenation.
The total mark should be the sum of marks awarded for each criterion as per the specific instructions for that criterion.
Double-check that each criterion's explanation, expected code, and student's code are correctly matched and relevant to that specific criterion.
Evaluate each criterion independently. The presence or absence of changes for one criterion should not affect the evaluation of other criteria.
For each criterion, focus solely on the specific aspect being evaluated, ignoring the overall completeness or correctness of the code.
When evaluating specific keywords or operators (like and, =, etc.), search for these terms without considering surrounding punctuation or quotes. The presence of the exact term is what matters, not its formatting.
Criteria Breakdown (10 Marks Total):

Global Variable Initialization (1 mark)

Description: Check that a new variable celsius is created as an integer and set to 0.
Expected Code: celsius = 0
Mark: 1 if the line is correct; otherwise, 0.
User Input Prompt (1 mark)

Description: The student must use input() to prompt the user for input.
Expected Code: Any use of input() with an appropriate prompt message, e.g., input("Enter a temperature in Celsius: ")
Mark: 1 if input() is used with an appropriate prompt message; otherwise, 0.
Input Conversion to Integer (1 mark)

Description: The student must correctly convert the string input to an integer using int().
Expected Code: celsius = int(input("Enter a temperature in Celsius: ")) or celsius = int(celsius)
Mark: 1 if the input is correctly converted to an integer using proper syntax; otherwise, 0.
Lower Bound Check (1 mark)

Description: Use the correct variable and relational operator to check that celsius is greater than or equal to -10.
Acceptable Forms Include: -10 <= celsius OR celsius >= -10
Mark: 1 if the correct variable and relational operator for the lower bound are used; otherwise, 0.
Upper Bound Check (1 mark)

Description: Check that the if statement includes the upper bound check for celsius.
Acceptable Forms Include: Any form that checks celsius is less than or equal to 35 within brackets, e.g., (celsius <= 35)
Mark: 1 if the upper bound check is present within brackets in the if statement; otherwise, 0.
Logical Operator Usage (1 mark)

Description: Check for the presence of the and logical operator in the if statement.
Expected Forms Include: Any use of and within the if statement line, regardless of surrounding code.
Mark: 1 if and is used in the if statement line; otherwise, 0.
Temperature Calculation (1 mark)

Description: Check for the presence of the calculation celsius * 2 + 30 anywhere in the code.
Expected Code: celsius * 2 + 30
Mark: 1 if the exact calculation celsius * 2 + 30 is present; otherwise, 0.
Assignment Operator Usage (1 mark)

Description: Check for the presence of the assignment operator = immediately following fahrenheit within the if statement block.
Expected Code: fahrenheit =
Mark: 1 if fahrenheit = appears within the if statement block; otherwise, 0.
String Concatenation in Print Statement (1 mark)

Description: Correctly concatenate strings in the print statement.
Expected Code: print(str(celsius) + "°C is approximately " + str(fahrenheit) + "°F")
Mark: 1 if the concatenation is functionally correct; otherwise, 0.
Invalid Input Message (1 mark)

Description: Add an appropriate error message within the else block.
Expected Code: Any suitable error message, e.g., print("Error: Temperature must be between -10 and 35 Celsius.")
Mark: 1 if a suitable error message is printed within the else block; otherwise, 0.
JSON Output Format:

{
  "totalMark": X,
  "markingBreakdown": [
    {
      "criterionNumber": "1",
      "criterionDescription": "Global Variable Initialization",
      "initialCode": "...",
      "expectedCode": "...",
      "studentCode": "...",
      "markAwarded": 0 or 1,
      "explanation": "..."
    },
    ...
  ],
  "suggestions": [
    "Specific improvement suggestion 1",
    "Specific improvement suggestion 2"
  ],
  "overallComment": "Summary of the student's modifications and key areas to focus on"
}


Student’s Response:

<student_response> [Insert the student’s code here] </student_response>`
  },
  {
    id: "q2",
    text: `A program is written to assist with meal planning. It displays the names of different meals, stored in an array. The user enters a number. That number of meals is selected randomly from the array and displayed.

Amend the code below to:
- Fix the syntax error on line 4: import rundom
- Complete line 8 to make the meal names be string data types: mealTable = ["pasta", "salad", burger, "stir-fry", "tacos", "soup"]
- Fix the syntax error on line 16: for meal in mealTable
- Fix the logic error on line 19 that causes one less meal to be printed than is asked for: for count in range(numMeals - 1):
- Complete line 20 to generate a random number between 0 and 5: index = random.
- Fix the IndexError on line 21: name = mealTable[index + 1]
- Fix the NameError on line 22: print (nmae)

Use white space to improve the readability of the code.

Note: Do not change the functionality of the given lines of code. Do not add any additional functionality.`,
    marks: 8,
    summary: "Fix syntax and logic errors in meal planning program.",
    initialCode: `# -------------------------------------------------------------------
# Import libraries
# -------------------------------------------------------------------
import rundom
# -------------------------------------------------------------------
# Global variables
# -------------------------------------------------------------------
mealTable = ["pasta", "salad", burger, "stir-fry", "tacos", "soup"]
index = 0
name = ""
numMeals = 0
# -------------------------------------------------------------------
# Main program
# -------------------------------------------------------------------
print("Here is the meal table")
for meal in mealTable
    print(meal)
numMeals = int(input("How many meals do you want to plan (1-6)? "))
for count in range(numMeals - 1):
    index = random.
    name = mealTable[index + 1]
    print(nmae)`,
    prompt: `You are tasked with evaluating a student's response to a programming question based on the provided mark scheme. Your role is to mark the response and give clear, constructive feedback on each of the following areas, awarding marks strictly according to the rules set out in the mark scheme, even if the code is not perfect by programming language standards.

The student's response will be embedded between the <student_response> and </student_response> tags.

Follow these steps:

Analyze the student's response line by line.
Compare the student's response against the mark scheme and assess whether it meets the specific criteria required to award a mark.
Always check for the required changes outlined in the mark scheme, even if the issue (e.g., misspelling or error) is not present in the original code. Marks should be awarded based on whether the correction, if needed, was made according to the mark scheme.
Do not skip or mark any criterion as "Not Applicable" unless the mark scheme explicitly states that certain criteria can be ignored. All criteria must be evaluated against the student’s response.
Award marks only if the student has met the criteria in the mark scheme, regardless of other issues that may exist in the code (e.g., language-specific syntax issues that are not covered by the mark scheme).
Feedback Format: Use the JSON format below to present your feedback in a structured, easy-to-read way.

Your response must match this format exactly:
{
  "totalMark": number,
  "taskEvaluations": [
    {
      "taskNumber": number,
      "taskDescription": string,
      "taskType": "Code Correction",
      "lineStatus": "Code Correct|Code Incorrect",
      "markAwarded": number,
      "feedback": string
    }
  ],
  "suggestions": string[],
  "overallComment": string
}

Guidelines for Each Criterion:

Import Libraries: Award 1 mark if the student changed rundom to random correctly.
Global Variables: Award 1 mark if the student correctly enclosed 'burger' in quotes in the mealTable array.
Printing All Meals: Award 1 mark if the student correctly added the missing colon to the for loop.
Choosing Meals: Award 1 mark if the student removed -1 from the range in for count in range(numMeals - 1):.
Generating Random Index: Award 1 mark if the student completed the call to random with randint(0, 5).
Fixing IndexError: Award 1 mark if the student removed +1 from name = mealTable[index + 1] and corrected it to name = mealTable[index].
Fixing NameError: Award 1 mark if the student corrected the misspelling of nmae to name. Even if the original code had no misspelling, still check this criterion to ensure the correct spelling is used.
White Space: Award 1 mark if the student made at least one additional use of white space in a correct location that improves readability.
Instructions for Feedback:

For each area, fill in the JSON format with:
Mark: Clearly state whether the mark was awarded (1 for awarded, 0 for not awarded).
Feedback: Provide specific feedback explaining the decision, with examples from the student's code if necessary. If marks are not awarded, explain why and offer guidance for improvement.
Final Steps:

Summarize the total marks awarded.
Offer overall feedback to encourage the student and highlight areas of improvement.
Code to be Amended:

-------------------------------------------------------------------
Import libraries
-------------------------------------------------------------------
import rundom

-------------------------------------------------------------------
Global variables
-------------------------------------------------------------------
mealTable = ["pasta", "salad", burger, "stir-fry", "tacos", "soup"] index = 0 name = "" numMeals = 0

-------------------------------------------------------------------
Main program
-------------------------------------------------------------------
print("Here is the meal table") for meal in mealTable print(meal) numMeals = int(input("How many meals do you want to plan (1-6)? ")) for count in range(numMeals - 1): index = random. name = mealTable[index + 1] print(nmae)

Student’s Response:

<student_response> [Insert the student’s code here] </student_response>`
 },
{
  id: "q3",
  text: `A program is used in a garden center that sells plants and gardening tools. 

The program reads data about garden spades from a file. The data is provided below.
[Display images: \\src\\assets\\image.png]

The program counts the number of steel spades. The program stores the names of 10 plant types in an array. It writes the names of the plants to a different file, one name per line. Plant names must be in lowercase.

The program displays this output on the screen:

Total spades: 15 Steel spades: 4
Wrote 10 plant names to file

The output shows 15 spades were read from the file, and four are made from steel.
It also shows 10 plant names were written to the file.

Amend the code to make the program work and produce the correct output.

You will need to:

- amend some lines of code
- choose between alternative lines of code. Make a choice by removing the # at the beginning of the line you choose to execute

Note: Do not change the functionality of the given lines of code. Do not add any additional functionality.`,
    marks: 15,
    summary: "Debug garden center inventory program with file operations.",
initialCode: `# -------------------------------------------------------------------
# Constants
# -------------------------------------------------------------------
SPECIFIED_MATERIAL = "Steel"

# =====> 1. Add the correct input file name
INPUT_FILE = 
# =====> End of Task 1

# =====> 2. Add the correct extension to this file name
OUTPUT_FILE = "Plants"
# =====> End of Task 2

# -------------------------------------------------------------------
# Global variables
# -------------------------------------------------------------------
# =====> 3. Complete the line with the correct variable name for the array of plants
 = ["Rose", "Tulip", "Daffodil", "Sunflower",
     "Lily", "Orchid", "Lavender",
     "Daisy", "Peony", "Hydrangea"]
# =====> End of Task 3

inFile = ""
outFile = ""
foundCount = 0

# =====> 4. Choose the correct value to initialize the variable
#total = 0.0
#total = ""
#total = 0 
#total = True
# =====> End of Task 4

# =====> 5. Choose the correct value to initialize the variable
#outLine = False
#outLine = ""
#outLine = 0.0
#outLine = 0
# =====> End of Task 5

# -------------------------------------------------------------------
# Main program
# -------------------------------------------------------------------

# Process the spades

# =====> 6. Choose the correct line to open the file
#inFile = open("Spades", "r")
#inFile = open("Spades", "a")
#inFile = open(INPUT_FILE, "a")
#inFile = open(INPUT_FILE, "r")
# =====> End of Task 6

for line in inFile:
    # =====> 7. Choose the correct line to locate the substring in the line
    #if line.find(SPECIFIED_MATERIAL) == -1:
    #if line.find(SPECIFIED_MATERIAL) != -1:
    #if line.find(SPECIFIED_MATERIAL) == False:
    #if line.find(SPECIFIED_MATERIAL) == True:
    # =====> End of Task 7
        foundCount += 1
    # =====> 8. Complete the line to increment total
        total = 
    # =====> End of Task 8

# =====> 9. Choose the correct line to close the file
#inFile.close()
#Spades.close()
#INPUT_FILE.close()
#outFile.close()
# =====> End of Task 9

# =====> 10. Choose the correct line to display the output
#print("Total spades:", foundCount, "SPECIFIED_MATERIAL", total)
#print("Total spades:", total)
#print("Total spades: " + str(foundCount) + " Steel " + str(total))
#print("Total spades: " + str(total) + " " + SPECIFIED_MATERIAL + " spades: " + str(foundCount))
# =====> End of Task 10

# Process the plants

# =====> 11. Choose the correct line to open the plants file
#outFile = open(OUTPUT_FILE, "r")
#outFile = open(OUTPUT_FILE, "w")
#outFile = open("Plants", "r")
#outFile = open(OUTPUT_FILE, "a")
# =====> End of Task 11

for plant in plantTable:
    # =====> 12. Choose the correct line to convert the case
    #plant = plant.lower()
    #plant = plant.isalpha()
    #plant = plant.format("{}")
    #plant = plant.islower()
    # =====> End of Task 12

    # =====> 13. Choose the correct line to complete the output line
    #outLine = plant
    #outLine = plant + "\\r"
    #outLine = plant + "\\n"
    #outLine = plant + ","
    # =====> End of Task 13

    # =====> 14. Choose the correct line to write the line to the file
    #outFile.writelines(plantTable)
    #outFile.write(outLine)
    #outFile.writelines(plant)
    #outFile.write(plant)
    # =====> End of Task 14

outFile.close()

# =====> 15. Choose the correct line to display the output
#print("Wrote", len(plantTable), "plant names to file")
#print("Wrote", total, "plant names to file")
#print("Wrote {:^5.2f} plant names to file".format(len(plantTable)))
#print("Wrote {:^5.2f} plant names to file".format(total))
# =====> End of Task 15

`,
  prompt:  `You are an AI evaluator assigned to grade a student's Python code submission. Your task is to evaluate each task in the student's submission separately, according to specific instructions and marking criteria. Each task is provided as a JSON structure below, where you will find the task number, type, description, and the student's submitted code for each task. Your goal is to provide an accurate, fair, and detailed evaluation for each task, helping the student understand their performance on each individual component.

Below is the JSON structure of the student's submission:

<student_response>
{
  "studentSubmission": {
    "tasks": [
      {
        "taskNumber": 1,
        "taskType": "Code Completion",
        "description": "Assign the input file name 'Spades.txt' to the variable INPUT_FILE.",
        "submittedCode": "INPUT_FILE = 'Spades.txt'"
      },
      {
        "taskNumber": 4,
        "taskType": "Multiple-Choice Uncommenting",
        "description": "Choose the correct value to initialize the variable",
        "submittedCode": {
          "options": [
            "#total = 0.0",
            "#total = \"\"",
            "#total = 0",
            "#total = True"
          ]
        }
      }
    ]
  }
}
</student_response>

CONTEXT

A garden center uses a Python program to process data about plants and gardening tools. The program:
- Reads data about garden spades from a file
- Counts the number of steel spades
- Stores the names of 10 plant types in an array
- Writes the names of the plants to a different file, one name per line, ensuring all plant names are in lowercase

STUDENT'S TASK

The student must:
1. Complete specific lines of code that are partially written or require additional input (code completion tasks)
2. Uncomment exactly one correct line of code from multiple provided options in each multiple-choice uncommenting task
3. Leave all other options in each multiple-choice set commented

EVALUATION INSTRUCTIONS

1. Code Completion Tasks (Tasks 1, 2, 3, and 8):
- Check if the student has correctly completed the code according to the task requirements
- Award 1 mark if the code is correct and meets all criteria
- Award 0 marks if the code is incorrect, incomplete, or does not meet criteria
- Provide specific feedback explaining any errors and how to correct them
- Reference the task description in your explanation

2. Multiple-Choice Uncommenting Tasks (Tasks 4-7 and 9-15):

Line Counting Rules:
a) Count a line as uncommented if and only if:
   - It does not start with '#' (ignoring leading whitespace)
   - It contains actual code (not just whitespace)
   - It is within the task's section (between task marker and end marker)

Evaluation Process:
a) First, count ALL uncommented lines within the task section
b) Then, apply these rules in order:
   - If count is 0: Status = "All Lines Commented", Mark = 0
   - If count > 1: Status = "Multiple Lines Uncommented", Mark = 0
   - If count = 1: Check if it matches the correct option
     * If matches: Status = "Uncommented Correctly", Mark = 1
     * If doesn't match: Status = "Uncommented Incorrectly", Mark = 0

Required Feedback Components:
- Number of uncommented lines found
- Status code (from above)
- For incorrect submissions:
  * If all commented: "No lines were uncommented. You must uncomment exactly one option."
  * If multiple uncommented: "Found [X] uncommented lines: [list them]. You must uncomment exactly one option."
  * If wrong line: "Incorrect option uncommented: [show line]. The expected answer was: [correct option]"

Example Cases:

1. All Commented:

# =====> Task 4
#total = 0.0
#total = ""
#total = 0 
#total = True
# =====> End of Task 4

Expected Feedback:
{
  "lineStatus": "All Lines Commented",
  "markAwarded": 0,
  "feedback": "No lines were uncommented. You must uncomment exactly one option."
}

2. Multiple Uncommented:

# =====> Task 4
total = 0.0
#total = ""
total = 0 
#total = True
# =====> End of Task 4

Expected Feedback:
{
  "lineStatus": "Multiple Lines Uncommented",
  "markAwarded": 0,
  "feedback": "Found 2 uncommented lines: 'total = 0.0', 'total = 0'. You must uncomment exactly one option."
}

3. Wrong Line Uncommented:

# =====> Task 4
#total = 0.0
total = ""
#total = 0 
#total = True
# =====> End of Task 4

Expected Feedback:
{
  "lineStatus": "Uncommented Incorrectly",
  "markAwarded": 0,
  "feedback": "Incorrect option uncommented: 'total = ""'. The expected answer was: 'total = 0'"
}

4. Correct Line Uncommented:

# =====> Task 4
#total = 0.0
#total = ""
total = 0 
#total = True
# =====> End of Task 4

Expected Feedback:
{
  "lineStatus": "Uncommented Correctly",
  "markAwarded": 1,
  "feedback": "Correct option uncommented: 'total = 0'"
}

CRITICAL REQUIREMENTS:
1. You MUST count all uncommented lines before making any evaluation decision
2. You MUST list all uncommented lines found when multiple lines are uncommented
3. You MUST show both the incorrect choice and the expected answer when wrong line is uncommented
4. You MUST use the exact status codes and feedback patterns shown in the examples
5. Partial uncommenting counts as an uncommented line
6. For Multiple-Choice Uncommenting tasks:
   - Each task's submittedCode contains an "options" array
   - You MUST check each option in the array separately
   - An option is COMMENTED if it starts with '#'
   - An option is UNCOMMENTED if it does not start with '#'
   - Example evaluation:
     Input: {
       "options": [
         "#total = 0.0",
         "#total = \"\"",
         "#total = 0",
         "#total = True"
       ]
     }
     Step 1: Check each option:
       Option 1: Starts with '#' -> COMMENTED
       Option 2: Starts with '#' -> COMMENTED
       Option 3: Starts with '#' -> COMMENTED
       Option 4: Starts with '#' -> COMMENTED
     Step 2: Count uncommented options = 0
     Result: "All Lines Commented", Mark = 0

JSON FEEDBACK FORMAT:

{
  "totalMark": X,
  "taskEvaluations": [
    {
      "taskNumber": 1,
      "taskDescription": "Task description here",
      "taskType": "Code Completion OR Multiple-Choice Uncommenting",
      "lineStatus": "Code Correct|Code Incorrect|Uncommented Correctly|Uncommented Incorrectly|Multiple Lines Uncommented|All Lines Commented",
      "markAwarded": 1 or 0,
      "feedback": "Detailed feedback following the required pattern for the task type"
    }
  ],
  "suggestions": [...],
  "overallComment": "..."
}


REMINDER: Each multiple-choice uncommenting task contains exactly four options, and ONLY ONE should be uncommented. Any deviation from this rule results in 0 marks for that task.`
  },
  {
    id: "q4",
    text: `A program converts a hexadecimal pattern to a positive denary integer. The user enters the hexadecimal pattern (using digits 0-9 and letters A-F). The program loops continually until the user inputs an empty pattern to stop the program. 

A multiplier is multiplied by 16 for each position from right to left to generate the place values for the hexadecimal pattern, i.e. 4096, 256, 16, 1. When a digit appears in the pattern, its decimal value is multiplied by the place value and added to the running total.

The table shows accurate test results for three inputs.

Input | Output
A5    | 165
FF    | 255
[empty] | Exits program

Task
The lines of code in the following program are mixed up. The indentation has been done for you. Amend the code below to make the program work and produce the correct output. You will need to rearrange the lines. Do not change the functionality of the given lines of code. 

Note: Do not add any additional functionality.`,
    marks: 15,
    summary: "Convert hexadecimal to decimal with input validation.",
    initialCode: `# Global variables
# -------------------------------------------------------------------
layout = "{} is {}"
hexa = ""
denary = 0
# -------------------------------------------------------------------
# Subprograms
# -------------------------------------------------------------------
def hexaLoop(pHexa):
# =====> Rearrange the mixed up lines
        total = total + value
    for index in range(len(pHexa) - 1, -1, -1):
    total = 0
        digit = pHexa[index]
    return (total)
    value = 0
    multiplier = 1
        value = multiplier * int(digit, 16)
    digit = ""
        multiplier = multiplier * 16
# End of mixed up lines
# -------------------------------------------------------------------
# Main program
# -------------------------------------------------------------------
# =====> Rearrange the mixed up lines
    hexa = input("Enter a hexadecimal number (empty to exit): ")
hexa = input("Enter a hexadecimal pattern (empty to exit): ")
    denary = hexaLoop(hexa)
    print(layout.format(hexa, denary))
while (hexa != ""):
# End of mixed up lines`,
    prompt: `You are tasked with evaluating a student's response to a question about rearranging lines of code to correctly implement a program that converts a hexadecimal pattern to a positive denary integer.

The student's response will be embedded between the <student_response> and </student_response> tags.

Important Marking Rules:

1. Subprogram Marking (8 marks):

   a) Initialization of variables (2 marks):
      • Award marks for variables initialized together before calculations
      • One mark for any two variables initialized together, up to maximum of 2
      • Variables to check: multiplier, total, digit, value

   b) Placement of for loop (1 mark):
      • Award 1 mark if for loop follows variable initialization
      • No marks if placed elsewhere

   c) Order of calculations (4 marks):
      • Each calculation earns 1 mark independently when in correct position:
        - digit first (1 mark) - gets mark if present and first
        - value after digit (1 mark) - gets mark if present and after digit
        - total after value (1 mark) - gets mark if present and after value
        - multiplier after value (1 mark) - gets mark if present and after value
      • Missing calculations get 0 marks but don't affect marks for other calculations
      • Both total and multiplier can come in either order after value
      
      Example marking:
      Case 1 (3 marks):
          digit = pHexa[index]          # 1 mark (first)
          value = multiplier * int(digit, 16)  # 1 mark (after digit)
          total = total + value         # 1 mark (after value)
          [multiplier missing but others correct: 0 mark]

      Case 2 (2 marks):
          digit = pHexa[index]          # 1 mark (first)
          value = multiplier * int(digit, 16)  # 1 mark (after digit)
          multiplier = multiplier * 16  # 0 mark (before total)
          total = total + value         # 0 mark (wrong order)

   d) Return statement placement (1 mark):
      • Must be last line in subprogram
      • No marks if not last line

2. Main Program Marking (5 marks):

   a) First input statement (1 mark):
      • Must be before while loop
      • No marks if inputs remain duplicated as in original

   b) While loop placement (1 mark):
      • Must immediately follow first input
      • Indentation doesn't affect this mark

   c) Subprogram call (1 mark):
      • Must be first operation inside while loop
      • Must follow while loop line

   d) Print statement (1 mark):
      • Must follow subprogram call
      • Must be before second input

   e) Second input (1 mark):
      • Must be last operation in loop
      • Must be after print statement

3. Additional Marking (2 marks):

   a) Test data handling (1 mark):
      • Program must loop with valid hex inputs
      • Must exit on empty input

   b) Indentation (1 mark):
      • Consistent throughout
      • No partial marks

Important Notes:
1. Award 0 marks for main program if code shows no meaningful rearrangement
2. Original code contains two input statements intentionally - student must rearrange these correctly
3. Evaluate subprogram and main program independently
4. Indentation only affects the specific indentation mark

Expected Code Structure:
1. First input statement
2. While loop
3. Inside loop:
   - Subprogram call
   - Print statement
   - Second input statement

JSON Response Format:
{
  "totalMark": number,
  "taskEvaluations": [
    {
      "taskNumber": number,
      "taskDescription": string,
      "taskType": "Code Rearrangement",
      "lineStatus": "Code Correct|Code Incorrect",
      "markAwarded": number,
      "feedback": string
    }
  ],
  "suggestions": string[],
  "overallComment": string
}

The student's code submission is formatted as JSON for evaluation:

<student_response>
[Student's code will be inserted here]
</student_response>`
  },
  {
    id: "q5",
    text: `A program is being developed to generate a unique book identifier for a library system. The letter part of the identifier is made up of the first three letters of the book title joined with the first letter of the author's last name. All letters must be in uppercase. The number part of the identifier is the product of the ASCII values for each of the digits in the publication year (yyyy).

For example, the identifier for the book "The Great Gatsby" by F. Scott Fitzgerald, published in 1925, is THEF14400, all in uppercase.

Instructions to the student:

- Ensure local and global variables with the same names are not confused – change the names (spellings) of the local variables to distinguish them from the global variables with the same name.
- Display a library header – add a procedure, with no parameters, to display a header message for the library system, and call the header procedure before taking input from the user.
- Ensure the book title and author's last name are all uppercase – convert the book title and author's last name to uppercase after they are inputted by the user.
- Validate the publication year in the main program using the built-in string manipulation subprograms – check that only the digits 0 to 9 appear in the publication year and that it is exactly 4 digits long. Call the makeIdentifier() function if the publication year is valid. Inform the user if the publication year is invalid. Invalid input should not be processed.
- Generate the correct number part of the identifier in the makeIdentifier() function. 
- Correct the logic error caused by using the int() function in the number part calculation rather than using a function that returns the ASCII value of the character.

Do not add any additional functionality.`,
    marks: 15,
    summary: "Generate a unique book identifier with validation.",
    initialCode: `# Global variables
# -------------------------------------------------------------------
bookTitle = ""
authorLastName = ""
pubYear = ""
bookID = ""

# -------------------------------------------------------------------
# Subprograms
# -------------------------------------------------------------------
# =====> Change the names of the local variables to distinguish them
#        from the global variables with the same name
def makeIdentifier(bookTitle, authorLastName, pubYear):
    letterPart = ""
    numberPart = 1
    letterPart = bookTitle[:3] + authorLastName[0]  # Letter part
    # =====> Correct the logic error caused by using the int() function
    #        in the number part calculation rather than using a function
    #        that returns the ASCII value of the character
    for digit in pubYear:
        numberPart = numberPart * int(digit)
    identifier = letterPart + str(numberPart)
    return (identifier)

# =====> Add a procedure, with no parameters, to display a
#        header message for the library system

# -------------------------------------------------------------------
# Main program
# -------------------------------------------------------------------
# =====> Call the header procedure before taking input from the user

# Get book title and author's last name from the user
bookTitle = input("Enter the book title: ")
authorLastName = input("Enter the author's last name: ")

# =====> Convert book title and author's last name to uppercase after they
#        are inputted by the user

# Get publication year from the user
pubYear = input("Enter the publication year (yyyy): ")

# =====> Check that only the digits 0 to 9 appear in the publication year
#        and that it is exactly 4 digits long

# =====> Call the makeIdentifier() function, if the publication year is valid
bookID = makeIdentifier(bookTitle, authorLastName, pubYear)
print(bookID)

# =====> Tell the user if the publication year is invalid`,
    prompt: `You are an AI responsible for marking a student's response to a programming task worth 15 marks. The question is divided into three main sections: makeIdentifier subprogram, header subprogram, and main program, with additional marks for solution design and functionality.

The student's response will be embedded between the <student_response> and </student_response> tags.

Instructions:

Solution Design: Do not award any marks for Solution Design (0-3) if the student’s code is simply a copy-paste of the original code without meaningful changes. Solution Design marks are awarded only if the student has modified the original code in a way that demonstrates problem decomposition, such as:

Renaming variables in makeIdentifier.
Adding a new subprogram (e.g., a header message procedure).
Implementing logic for validation.
Original Code Comparison: Compare the student response to the original code below. If they match without any changes, then award 0 marks for Solution Design.

Question:

A program is being developed to generate a unique book identifier for a library system. The letter part of the identifier is made up of the first three letters of the book title joined with the first letter of the author's last name. All letters must be in uppercase. The number part of the identifier is the product of the ASCII values for each of the digits in the publication year (yyyy).

For example, the identifier for the book "The Great Gatsby" by F. Scott Fitzgerald, published in 1925, is THEF14400, all in uppercase.

Instructions to the student:

Ensure local and global variables are spelt differently.
Display a library header – add a procedure, with no parameters, to display a header message for the library system, and call the header procedure before taking input from the user.
Ensure the book title and author's last name are all uppercase – convert the book title and author's last name to uppercase after they are inputted by the user.
Validate the publication year in the main program using the built-in string manipulation subprograms – check that only the digits 0 to 9 appear in the publication year and that it is exactly 4 digits long. Call the makeIdentifier function if the publication year is valid. Inform the user if the publication year is invalid. Invalid input should not be processed.
Generate the correct number part of the identifier in the makeIdentifier function. Correct the logic error caused by using the int() function in the number part calculation rather than using a function that returns the ASCII value of the character.
Do not add any additional functionality.
Code that needs to be amended:

-------------------------------------------------------------------
Global variables
-------------------------------------------------------------------
bookTitle = "" authorLastName = "" pubYear = "" bookID = ""

-------------------------------------------------------------------
Subprograms
-------------------------------------------------------------------
=====> Change the names of the local variables to distinguish them
from the global variables with the same name
def makeIdentifier(bookTitle, authorLastName, pubYear): letterPart = "" numberPart = 1 letterPart = bookTitle[:3] + authorLastName[0] # Letter part # =====> Correct the logic error caused by using the int() function # in the number part calculation rather than using a function # that returns the ASCII value of the character for digit in pubYear: numberPart = numberPart * int(digit) identifier = letterPart + str(numberPart) return (identifier)

=====> Add a procedure, with no parameters, to display a
header message for the library system
-------------------------------------------------------------------
Main program
-------------------------------------------------------------------
=====> Call the header procedure before taking input from the user
Get book title and author's last name from the user
bookTitle = input("Enter the book title: ") authorLastName = input("Enter the author's last name: ")

=====> Convert book title and author's last name to uppercase after they
are inputted by the user
Get publication year from the user
pubYear = input("Enter the publication year (yyyy): ")

=====> Check that only the digits 0 to 9 appear in the publication year
and that it is exactly 4 digits long
=====> Call the makeIdentifier() function, if the publication year is valid
bookID = makeIdentifier(bookTitle, authorLastName, pubYear) print(bookID)

=====> Tell the user if the publication year is invalid
Mark Scheme (15 marks total):

makeIdentifier Subprogram (4 marks)

5.1: Parameter Name Change (1 mark)
• Award mark if ANY parameter name is spelt differently from original names
• Original names: bookTitle, authorLastName, pubYear
• Example: bTitle is different from bookTitle, so award mark
• Abbreviations and similar names count as different

5.2: All Parameter Names Changed (1 mark)
• Award mark if ALL THREE parameter names have different spellings from originals
• Must change: bookTitle, authorLastName, pubYear
• Similar but different names are acceptable
• Example: (bTitle, authLName, pYear) would get the mark


5.3: Meaningful Parameter Names (1 mark)
• Award mark if new names clearly indicate their purpose
• Abbreviations are acceptable if meaning is clear
• Examples of meaningful names:
  - bTitle or bookT (for bookTitle)
  - authLName or authorLN (for authorLastName) 
  - pYear or pubYr (for pubYear)
• Names should relate to what they represent

5.4: Correct use of ord() to calculate the number part – 1 mark

Header Subprogram (2 marks)

5.5: Header subprogram defined using def, with a meaningful name and no parameters – 1 mark
5.6: The library header message is fit for purpose – 1 mark

Main Program (3 marks)

5.7: Header subprogram called before taking user inputs – 1 mark
5.8: Convert book title and author's last name to uppercase after input – 1 mark
5.9: Validate publication year and process valid/invalid cases – 1 mark

Solution Design (3 marks) – Levels-Based

Level 0 (0 marks): If the student only copies and pastes the original code without meaningful changes.
Level 1 (1 mark): Some decomposition is attempted, but the solution may be incomplete.
Level 2 (2 marks): Most components decomposed, logic mostly appropriate.
Level 3 (3 marks): Clear decomposition, appropriate variables, and logic.

Functionality (3 marks) – Levels-Based

Level 0 (0 marks): Program is incomplete or inaccurate.
Level 1 (1 mark): Program runs but has limited functionality.
Level 2 (2 marks): Program mostly meets requirements.
Level 3 (3 marks): Program fully meets requirements and is accurate.

Detailed Feedback Instructions:

Provide detailed marking for each criterion, assigning marks and providing explanations for awarded or deducted marks. Format feedback in the JSON format below:

JSON Feedback Format:
Please format your response in JSON as follows:
{
  "totalMark": X,
  "taskEvaluations": [
    {
      "taskNumber": number,
      "taskDescription": string,
      "taskType": string,
      "lineStatus": "Code Correct|Code Incorrect",
      "markAwarded": number,
      "feedback": string
    }
  ],
  "suggestions": [
    "Ensure distinct local variable names to avoid confusion with global variables.",
    "Use .upper() to convert user input to uppercase as required."
  ],
  "overallComment": "Your solution shows a good attempt at decomposition and functionality..."
}
Student Response:

<student_response> [Insert the student’s code here] </student_response>`
  },
  {
    id: "q6",
    text: `A school library requires a program to verify student access to their digital resources. The student IDs and their assigned access codes are stored in a two-dimensional array. 

Edit the code below to implement this authentication system.

Inputs:
- The program must prompt for and accept a student ID and an access code
- Neither the ID nor the access code should be blank

Process:
- Search the array to verify the student's ID and access code combination
- The search algorithm must work efficiently for any size of array

Outputs:
- Display "Welcome" when a correct ID and access code combination is found
- Display "Incorrect password" when the ID exists but the access code doesn't match
- Display "User not found" when the ID doesn't exist in the system

Use appropriate comments to explain your code. Implement proper spacing and layout for readability.

Note: Don't add any additional functionality.

The userTable array contains student IDs in the first column and their corresponding access codes in the second column.`,
    marks: 15,
    summary: "Implement student authentication system.",
    initialCode: `# -------------------------------------------------------------------
# Global variables
# -------------------------------------------------------------------
userTable = [["LArmstrong3", "RedChair"],
             ["SBarrett7", "PurpleDesk"],
             ["EChisholm4", "YellowStool"],
             ["VDunn1", "OrangeFuton"],
             ["DElms5", "GreenCouch"],
             ["EFirsova13", "PinkMattress"],
             ["JGolland6", "GreenTable"],
             ["FHartley13", "BrownMirror"],
             ["DJohnstone12", "GoldBed"],
             ["GKirkhope8", "WhiteNights"],
             ["LLemon8", "BeigeDresser"],
             ["HMacCunn6", "GreyOttoman"],
             ["PNewland10", "BlackWardrobe"],
             ["AOldham5", "OrangeFuton"],
             ["JPook8", "YellowStool"]]

# =====> Write your code here


# -------------------------------------------------------------------
# Main program
# -------------------------------------------------------------------

# =====> Write your code here`,
    prompt: `You are a meticulous programming assessor tasked with marking student solutions for the Library System Authentication Task. Your role is to:

Carefully examine the provided student code by:

Identifying ALL variable declarations and initializations
Locating ALL control structures (loops, conditionals)
Finding ALL array accesses
Noting ALL input/output operations
Examining ALL logical operators and conditions
Reviewing ALL code organization and commenting
For Part 1 (Technical Implementation - 6 marks), evaluate EACH criterion independently:

Mark 1.1-1.6 strictly as per specification.
Only award marks where implementation exactly matches requirements.
Record specific evidence from the code for each mark awarded.
If no changes were made to template code, award 0 for that section.
For Part 2 (Levels-based Assessment - 9 marks), for EACH category:

Start at Level 0.
Move up levels ONLY if ALL criteria for that level are met.
Provide specific code examples justifying the level awarded.
If no changes were made to template code, award Level 0.
Present your evaluation in JSON format, following the structure provided below.

Marking Criteria

Technical Implementation (6 marks)
Each criterion (1.1 to 1.6) is assessed independently:

1.1 to 1.6: Award 1 mark each if the student’s code meets the specified requirement exactly.
Levels-based Assessment (9 marks)
Each category (Solution Design, Good Programming Practice, Functionality) is assessed independently:

Begin assessment at Level 0.
Progress to higher levels only if all criteria for the preceding level have been met.
Evaluation Format

For each part, provide specific code evidence for every awarded mark and level.

JSON Feedback Format

Please format your response in JSON as follows:


{
  "totalMark": X,
  "technicalImplementation": {
    "total": x,
    "criteria": [
      {
        "criterionNumber": "1.1",
        "description": "Description of criterion 1.1",
        "studentCode": "[Student’s code here]",
        "markAwarded": 0 or 1,
        "evidence": "Specific evidence from student’s code"
      },
      {
        "criterionNumber": "1.2",
        "description": "Description of criterion 1.2",
        "studentCode": "[Student’s code here]",
        "markAwarded": 0 or 1,
        "evidence": "Specific evidence from student’s code"
      },
      ...
    ]
  },
  "levelsBasedAssessment": {
    "total": x,
    "solutionDesign": {
      "levelAwarded": 0, 1, 2, or 3,
      "evidence": [
        "Specific example from code showing Solution Design criteria met"
      ]
    },
    "goodProgrammingPractice": {
      "levelAwarded": 0, 1, 2, or 3,
      "evidence": [
        "Specific example from code showing Good Programming Practice criteria met"
      ]
    },
    "functionality": {
      "levelAwarded": 0, 1, 2, or 3,
      "evidence": [
        "Specific example from code showing Functionality criteria met"
      ]
    }
  },
  "suggestions": [
    "Provide clear, actionable suggestions for each area where the student lost marks.",
    "For example: Ensure you handle blank inputs properly in the authentication process."
  ],
  "overallComment": "A brief summary of the student’s overall performance, including strengths and areas for improvement."
}
Important Assessment Rules:

Award NO MARKS for unchanged template code.
Require EXACT matches for technical implementation marks.
Use ONLY the level descriptors provided.
Provide SPECIFIC evidence for every mark awarded.
Check code line-by-line against each criterion.
Award marks INDEPENDENTLY for each section.
Give detailed, criterion-referenced feedback.
If uncertain about a criterion, DO NOT award the mark.

Student Response:

<student_response> [Insert the student’s code here] </student_response>`
  }
];

export default questions;
